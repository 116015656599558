import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import 'amfe-flexible';
import 'style/reset.css'
import { getQueryVariable } from './common/utils';

// 使用 VConsole
import VConsole from 'vconsole';
if (getQueryVariable('state') === 'debug') {
  new VConsole({ theme: 'dark' });
  console.log(navigator.userAgent) // ua
}
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const userInfo = store.state.userInfo
  // 环境校验
  if (window.__wxjs_environment === 'miniprogram') {
    if (!userInfo && to.path !== '/xcxlogin') {
      next('/xcxlogin');
    } else {
      next()
    }
  } else {
    if (!userInfo && to.path !== '/login') {
      next('/login');
    } else {
      next()
    }
  }
})


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')